import {reactive, toRefs, defineComponent, onBeforeMount, onMounted,onActivated, getCurrentInstance, nextTick} from 'vue';
import VideoPlayUtil ,{IVideoPlayDataObj} from './videoPlayUtil';
import {useRoute} from "vue-router";
export default defineComponent({
    name:'personCenter',
    beforeRouteEnter(to,from,next){
        // if(sessionStorage.getItem('userInfo')){
        //     next();
        // }else{
        //     next('/clientLogin');
        // }
        next();
    },
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let route = useRoute();
        let dataObj:IVideoPlayDataObj=reactive<IVideoPlayDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            otherParams:{
                url:''//视频地址
            }
        })
        onActivated(()=>{
            buildRoutParams();
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new VideoPlayUtil(proxy,dataObj);
            buildRoutParams();
        })
        onMounted(()=>{
            nextTick(async()=>{

            })
        })
        //获取路由的参数
        const buildRoutParams=()=>{
            nextTick(()=>{
                //tmd，http的播放不了，虽然地址上看着是http的可以
                let url:any=route.query.url;
                if(!url.startsWith('https'))url=url.replace("http","https");
                dataObj.otherParams.url=url;
                // dataObj.otherParams.url=route.query.url as string;
            })
        }
        const backHandler=async ()=>{
            await proxy.$router.replace({path:'/clientRepairVideos'});
        }
        return{
            ...toRefs(dataObj),backHandler
         
        }
    }
});